@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overscroll-behavior: none;
    margin: 0 !important;
}

button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.tooltip {
    @apply invisible absolute opacity-0;
}

.has-tooltip:hover .tooltip {
    @apply visible z-50 opacity-100;
}

*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}